<template>
  <Modal
    :value="value"
    title="车辆跟踪"
    @input="(val) => $emit('input', val)"
    fullscreen
    footer-hide
    @on-visible-change="modalShow"
  >
    <div v-if="value" class="container" ref="container"></div>
  </Modal>
</template>

<script>
export default {
  name: "",
  props: {
    value: {
      type: Boolean,
      default: "",
    },
  },
  data() {
    return {
      map: null
    };
  },
  methods: {
    initMap() {
      this.map = new BMapGL.Map(this.$refs.container)
      this.map.centerAndZoom(new BMapGL.Point(106.551556, 29.563009), 9)
      this.map.enableScrollWheelZoom(true);
    },
    modalShow(visible) {
      if (visible) {
        this.$nextTick(() => {
          this.initMap()
        })
      }
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  height: 100%;
}
</style>